<template>
  <v-row :class="byAcordeon ? 'pa-0 ma-0 white' : 'px-3'">
    <v-col :class="byAcordeon ? 'px-0 py-0' : 'py-6'" cols="12">
      <v-data-table
        loading-text="Carregando..."
        :dense="byAcordeon"
        :headers="headersDependents"
        :items="byAcordeon ? dependents : responseData.content"
        :loading="loadingBeneficiary"
        :items-per-page="itemsPerPage"
        hide-default-footer
        item-key="contractPlanId"
        disable-sort
        class="elevation-1 px-0"
      >
        <template v-slot:[`item.dependent`]="{ item }">
          {{ item.physicalPersonName ? formatter.formatToTitleCase(item.physicalPersonName) : null }}
        </template>
        <template v-slot:[`item.financialGroupId`]="{ item }">
          {{ item.financialGroupName ? item.financialGroupName : '-' }}
        </template>
        <template v-slot:[`item.carrierName`]="{ item }">
          {{ item.carrierName ? item.carrierName : '-' }}
        </template>
        <template v-slot:[`item.contract`]="{ item }">
          {{ formatContractName(item) }}
        </template>
        <template v-slot:[`item.subContract`]="{ item }">
          {{ formatSubContractName(item) }}
        </template>
        <template v-slot:[`item.plan`]="{ item }">
          {{ formatPlanDescription(item) }}
        </template>
        <template v-slot:[`item.kinship`]="{ item }">
          {{ item.kinship ? formatter.formatToTitleCase(item.kinship) : '-' }}
        </template>
        <template v-slot:[`item.startDate`]="{ item }">
          {{ formatter.formatDate(item.startDate) }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-menu open-on-click bottom offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                dark
                v-bind="attrs"
                v-on="on"
                icon
              >
                <v-icon color="textPrimary" size="20">fas fa-ellipsis-v</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item @click="$emit('onClickHandler', item)">
                <v-list-item-icon class="mr-2">
                  <v-icon size="20" color="textPrimary">fas fa-edit</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ titleText }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
      <div v-if="!byAcordeon" class="float-right pt-2">
        <v-pagination
          v-model="page"
          color="textPrimary"
          :length="responseData.totalPages"
          :disabled="loadingBeneficiary"
          :total-visible="9"
          @input="setPagination()"
        />
      </div>
    </v-col>
  </v-row>
</template>

<script>
import Formatters from '@/shared/formatters/formatters';

export default ({
  data: () => ({
    page: 1,
    itemsPerPage: 10,
    headersDependents: [
      {
        text: 'Dependente',
        value: 'dependent',
        align: 'center',
        class: 'text--truncate',
        sortable: false,
      },
      {
        text: 'Operadora',
        value: 'carrierName',
        align: 'center',
        class: 'text--truncate',
        sortable: false,
      },
      {
        text: 'Grupo Financeiro',
        value: 'financialGroupId',
        align: 'center',
        class: 'text--truncate',
        sortable: false,
      },
      {
        text: 'Contrato',
        value: 'contract',
        align: 'center',
        class: 'text--truncate',
        sortable: false,
      },
      {
        text: 'Sub-contrato',
        value: 'subContract',
        align: 'center',
        class: 'text--truncate',
        sortable: false,
      },
      {
        text: 'Plano',
        value: 'plan',
        align: 'center',
        class: 'text--truncate',
        sortable: false,
      },
      {
        text: 'Parentesco',
        value: 'kinship',
        align: 'center',
        class: 'text--truncate',
        sortable: false,
      },
      {
        text: 'Início de Vigência',
        value: 'startDate',
        align: 'center',
        class: 'text--truncate',
        sortable: false,
      },
      {
        text: 'Ações',
        value: 'actions',
        align: 'center',
        class: 'text--truncate',
        sortable: false,
      },
      {
        text: ' ',
        align: 'right',
        value: 'data-table-expand',
        class: 'text--truncate',
        sortable: false,
      },
    ],
  }),

  props: {
    dependents: {
      type: Array,
      default() {
        return [];
      },
    },
    responseData: {
      type: Object,
      default() {
        return {};
      },
    },
    byAcordeon: {
      type: Boolean,
      default() {
        return false;
      },
    },
    loadingBeneficiary: {
      type: Boolean,
      default() {
        return false;
      },
    },
    movementType: {
      type: String,
      default() {
        return '';
      },
    },
  },

  computed: {
    titleText() {
      if (this.movementType === 'delete') {
        return 'Excluir Movimentação';
      }
      if (this.movementType === 'edit') {
        return 'Editar Movimentação';
      }
      if (this.movementType === 'reativation') {
        return 'Reativar Movimentação';
      }
      return '-';
    },
  },

  methods: {
    formatContractName(item) {
      if (!item) {
        return '-';
      }
      const contractBenefitCarrier = item.contractBenefitCarrier || '-';
      const contractName = item.contractName || '-';

      return `${contractBenefitCarrier} - ${contractName}`;
    },
    formatSubContractName(item) {
      if (!item) {
        return '-';
      }
      const subcontractNumber = item.subcontractNumber || '-';
      const subcontractBusinessName = item.subcontractBusinessName || '-';

      return `${subcontractNumber} - ${subcontractBusinessName}`;
    },
    formatPlanDescription(item) {
      if (!item) {
        return '-';
      }
      const planDescription = item.planDescription || '-';
      const planProductCode = item.planProductCode || '-';
      const planName = item.planName || '-';

      return `${planProductCode} - ${planDescription !== '-' ? planDescription : planName}`;
    },
    setPagination() {
      const pagination = {
        page: this.page,
        totalPage: this.responseData.totalPages,
      };

      this.$emit('setPagination', pagination);
    },
  },

  created() {
    this.formatter = new Formatters();
  },
});
</script>
