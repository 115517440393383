<template>
  <v-col cols="12">
    <v-data-table
      loading-text="Carregando..."
      :headers="headers"
      :items="responseData.content"
      :loading="loadingBeneficiary"
      :single-expand="true"
      :expanded.sync="expanded"
      :items-per-page="itemsPerPage"
      hide-default-footer
      show-expand
      item-key="contractPlanId"
      class="elevation-1"
    >
      <template v-slot:[`item.data-table-expand`]="{ item, isExpanded, expand }">
        <v-icon
          v-if="item.dependents && item.dependents.length"
          :class="['v-data-table__expand-icon', { 'v-data-table__expand-icon--active': isExpanded }]"
          @click.stop="expand(!isExpanded)"
        >
          $expand
        </v-icon>
      </template>
      <template v-slot:[`item.holder`]="{ item }">
        {{ item.physicalPersonName ? formatter.formatToTitleCase(item.physicalPersonName) : null }}
      </template>
      <template v-slot:[`item.financialGroupId`]="{ item }">
        {{ item.financialGroupName }}
      </template>
      <template v-slot:[`item.contract`]="{ item }">
        {{ formatContractName(item) }}
      </template>
      <template v-slot:[`item.subContract`]="{ item }">
        {{ formatSubContractName(item) }}
      </template>
      <template v-slot:[`item.plan`]="{ item }">
        {{ formatPlanDescription(item) }}
      </template>
      <template v-slot:[`item.startDate`]="{ item }">
        {{ formatter.formatDate(item.startDate) }}
      </template>
      <template v-slot:[`item.dependents`]="{ item }">
        {{ item.dependents ? item.dependents.length : '-' }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-menu open-on-click offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark v-bind="attrs" v-on="on" icon>
              <v-icon color="textPrimary" size="20">fas fa-ellipsis-v</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item @click="$emit('onClickHandler', item)">
              <v-list-item-icon class="mr-2">
                <v-icon size="20" color="textPrimary">fas fa-edit</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ titleText }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length" class="mx-0 px-0">
          <DependentSearchTable
            :dependents="item.dependents"
            :byAcordeon="true"
            :movementType="movementType"
            @onClickHandler="onClickHandler"
          />
        </td>
      </template>
    </v-data-table>
    <div class="float-right pt-2">
      <v-pagination
        v-model="page"
        color="textPrimary"
        :length="responseData.totalPages"
        :disabled="loadingBeneficiary"
        :total-visible="9"
        @input="setPagination()"
      />
    </div>
  </v-col>
</template>

<script>
import Formatters from '@/shared/formatters/formatters';
import DependentSearchTable from '@/components/Beneficiary/Dependent/DependentSearchTable.vue';

export default ({
  components: { DependentSearchTable },
  data: () => ({
    page: 1,
    itemsPerPage: 10,
    expanded: [],
    options: {},
    headers: [
      {
        text: 'Titular',
        value: 'holder',
        align: 'center',
        class: 'text--truncate',
        sortable: false,
      },
      {
        text: 'Operadora',
        value: 'carrierName',
        align: 'center',
        class: 'text--truncate',
        sortable: false,
      },
      {
        text: 'Grupo Financeiro',
        value: 'financialGroupId',
        align: 'center',
        class: 'text--truncate',
        sortable: false,
      },
      {
        text: 'Contrato',
        value: 'contract',
        align: 'center',
        class: 'text--truncate',
        sortable: false,
      },
      {
        text: 'Sub-contrato',
        value: 'subContract',
        align: 'center',
        class: 'text--truncate',
        sortable: false,
      },
      {
        text: 'Plano',
        value: 'plan',
        align: 'center',
        class: 'text--truncate',
        sortable: false,
      },
      {
        text: 'Início de Vigência',
        value: 'startDate',
        align: 'center',
        class: 'text--truncate',
        sortable: false,
      },
      {
        text: 'Dependentes',
        value: 'dependents',
        align: 'center',
        class: 'text--truncate',
        sortable: false,
      },
      {
        text: 'Ações',
        value: 'actions',
        align: 'center',
        class: 'text--truncate',
        sortable: false,
      },
      {
        text: ' ',
        align: 'right',
        value: 'data-table-expand',
        class: 'text--truncate',
        sortable: false,
      },
    ],
  }),

  props: {
    responseData: {
      type: Object,
      default() {
        return {};
      },
    },
    loadingBeneficiary: {
      type: Boolean,
      default() {
        return false;
      },
    },
    movementType: {
      type: String,
      default() {
        return '';
      },
    },
  },

  computed: {
    titleText() {
      if (this.movementType === 'delete') {
        return 'Excluir Movimentação';
      }
      if (this.movementType === 'edit') {
        return 'Editar Movimentação';
      }
      if (this.movementType === 'reativation') {
        return 'Reativar Movimentação';
      }
      return '-';
    },
  },

  methods: {
    formatContractName(item) {
      if (!item) {
        return '-';
      }
      const contractBenefitCarrier = item.contractBenefitCarrier || '-';
      const contractName = item.contractName || '-';

      return `${contractBenefitCarrier} - ${contractName}`;
    },
    formatSubContractName(item) {
      if (!item) {
        return '-';
      }
      const subcontractNumber = item.subcontractNumber || '-';
      const subcontractBusinessName = item.subcontractBusinessName || '-';

      return `${subcontractNumber} - ${subcontractBusinessName}`;
    },
    formatPlanDescription(item) {
      if (!item) {
        return '-';
      }
      const planDescription = item.planDescription || '-';
      const planProductCode = item.planProductCode || '-';
      const planName = item.planName || '-';

      return `${planProductCode} - ${planDescription !== '-' ? planDescription : planName}`;
    },
    onClickHandler(item) {
      this.$emit('onClickHandler', item);
    },
    setPagination() {
      const pagination = {
        page: this.page,
        totalPage: this.responseData.totalPages,
      };

      this.$emit('setPagination', pagination);
    },
  },

  created() {
    this.formatter = new Formatters();
  },
});
</script>
