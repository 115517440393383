/* eslint-disable */
import HttpService from '../HttpService';
export default class BeneficiaryContractedPlanService {
  constructor() {
    this._httpService = new HttpService('/contract/beneficiary_contracted_plan');
  }

  async FindById(queryString) {
    return await this._httpService.get(`/${queryString}`);
  }

  async FindInsuredByContractedPlanId (contractedPlanId) {
    return await this._httpService.get(`/${contractedPlanId}/contracted_plan_data`);
  }
  
  async FindAllByFilters(params) {
    return await this._httpService.get(`/v2/find_all_by_filters/permiteds?${params}`);
  }

  async Delete(id) {
    return await this._httpService.delete(`/${id}`);
  }
};
